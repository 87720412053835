import { createTheme } from '@mui/material/styles'
import Constants from '../../constants'
import '@fontsource/roboto' // default font
import '@fontsource/work-sans/800.css' // headlines
import '@fontsource/work-sans/600.css'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    // standard breakpoints, retained for Grid
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    // added breakpoints
    mobile: true
    tablet: true
    desktop: true
  }
}

const breakpoints = {
  values: {
    xs: 0,
    // We design for Mobile (375px), but the CSS needs to do a best effort for for 0.
    mobile: 0,
    sm: Constants.Layout.Breakpoints.Mobile,
    tablet: Constants.Layout.Breakpoints.Tablet,
    md: Constants.Layout.Breakpoints.Tablet,
    desktop: Constants.Layout.Breakpoints.Desktop,
    lg: Constants.Layout.Breakpoints.Desktop,
    xl: Constants.Layout.Breakpoints.Desktop,
  },
}

export interface ThemeComponentProps {
  breakpoints
}

let theme = createTheme({ breakpoints }) // for using correct breakpoints in next createTheme
theme = createTheme({
  breakpoints: breakpoints,
  components: {
    // Global CSS
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'Roboto',
          backgroundColor: Constants.Layout.Colors.Border,
          color: Constants.Layout.Colors.Text,
          fontSize: '12pt',
        },
        h1: {
          fontFamily: 'Work Sans',
          color: Constants.Layout.Colors.Headline,
          textTransform: 'uppercase',
          textAlign: 'center',
          fontSize: '4.9vw',
          fontWeight: 800,
          [theme.breakpoints.up('tablet')]: {
            fontSize: 40,
          },
        },
        h2: {
          color: Constants.Layout.Colors.Headline,
          fontWeight: 400,
          fontSize: 20,
          [theme.breakpoints.up('tablet')]: {
            fontSize: 30,
          },
        },
        a: {
          color: Constants.Layout.Colors.Blue100,
          fontWeight: 'bold',
          textDecoration: 'none',
          cursor: 'pointer',
          '&:hover': {
            color: Constants.Layout.Colors.Blue75,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
  },
})

export { theme }
