// Select Input options
export default {
  External: {
    WhatsApp: 'http://wa.me/01752275436',
    Instagram: 'https://www.instagram.com/karrierepapierverpackung',
    Facebook: 'https://www.facebook.com/karrierepapierverpackung/',
  },
  Internal: {
    Datenschutz: '/datenschutz/',
    Unternehmen: '/unternehmen/',
    Blog_BewerbungSchreiben: '/blog/so-schreibst-du-eine-gute-bewerbung/',
  },
}
