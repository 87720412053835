export default {
  Breakpoints: {
    RockBottom: 320,
    Mobile: 375,
    Tablet: 768,
    Desktop: 1366,
  },
  Colors: {
    Green100: '#b4c404',
    Green75: '#c7d343',
    Green50: '#d9e181',
    Green25: '#ecf0c0',
    Blue100: '#307adb',
    Blue75: '#649be4',
    Blue50: '#97bced',
    Blue25: '#cbdef6',
    Headline: '#262d33',
    Text: '#4b5157',
    Border: '#f5f6f6',
    White: '#ffffff',
    Grey: '#939699',
    DarkGrey: '#444',
    WhatsApp: '#25d366',
    Pink: '#da1d55',
  },
  Dimensions: {
    HeaderHeight: 70,
    Slide: {
      Width: 1450,
      Height: 600,
    },
  },
}
