// TODO: for future use

// Select Input options
export default {
  Verbaende: [
    'ppk Rheinland-Pfalz',
    'UPV Nordrhein',
    'VBP Nordost',
    'VBPV/Baypapier',
    'VDT',
    'vpdm',
    'VPI',
    'VPK Nord',
    'VPU Mitte',
    'VPV Westfalen',
    'Kein Verbandsmitglied',
  ],
  FirmenkontaktAnliegen: ['Ausbildung', 'Praktikum', 'Jobsuche', 'B2B'],
  DefaultAllowedFileTypes: ['jpeg', 'jpg', 'png', 'pdf', 'docx'],
}
// Patterns
export const EMAIL_PATTERN: RegExp =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const NUMBER_PATTERN: RegExp = /(^[-]?\d*[\,|\.]?\d+)$/
export const PHONENUMBER_PATTERN: RegExp =
  /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/
