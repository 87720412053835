import React from 'react'
import { QueryParamProvider } from 'use-query-params'
import { GatsbyAdapter } from './src/util/query-params'

// Required in gatsby-browser.js and gatsbyh-ssr.js to make query params work
export const wrapRootElement = ({ element }) => (
  <QueryParamProvider adapter={GatsbyAdapter} enableBatching={true}>
    {element}
  </QueryParamProvider>
)

// Prevent use-query-param from jumping to the top on every parameter change
export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps,
  getSavedScrollPosition,
}) => {
  // Don't scroll if path name hasn't changed (GET params are ignored)
  if (routerProps.location.pathname === prevRouterProps?.location?.pathname) {
    return false
  }
  // Otherwise default behavior
  return getSavedScrollPosition(routerProps.location) || [0, 0]
}
